<template>
  <div class="app-box">
    <div class="title-box">
      <!-- <div class="back" @click="back">
        <a-icon type="left" />
      </div> -->
      <div class="line"></div>
      <p class="title">大屏配置</p>
    </div>
    <a-divider style="margin-left: -10px" />

    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="{ span: 2, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="区域名称：" :colon="false"
        >{{ form.name }}
      </a-form-model-item>
      <a-form-model-item label="大屏访问：" :colon="false">
        <span
          style="
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            color: #1677ff;
          "
          @click="toIOC"
          >http://ioc.jsspdzkj.cn/</span
        >
      </a-form-model-item>
      <a-form-model-item label="大屏口令：" :colon="false">
        <div class="secret_box">
          <span class="secret">{{ form.secret }}</span>
          <span @click="getUpdateSecret" class="btn" style="margin-left: 20px"
            >刷新口令</span
          >
          <span
            v-clipboard:copy="form.secret"
            v-clipboard:success="copySecret"
            class="btn"
            style="margin-left: 20px"
            >复制口令</span
          >
        </div>
      </a-form-model-item>
      <a-form-model-item label="报警音效：" :colon="false"
        >{{ form.audio }}
        <div class="audio_class">
          <span
            v-for="(item, index) in audio"
            :key="index"
            @click="setAudio(item.value)"
            :class="activeAudio == item.value ? 'active_class' : ''"
          >
            <img
              v-if="activeAudio == item.value"
              src="../../assets/audio1.png"
              alt=""
              style="width: 22px; height: 22px"
            />
            <img
              src="../../assets/audio2.png"
              v-else
              alt=""
              style="width: 22px; height: 22px; transform: translateY(1px)"
            /><span class="dot">●</span>{{ item.name }}
            <img
              src="../../assets/3.png"
              v-if="activeAudio == item.value"
              alt=""
              style="margin-left: 10px"
            />
          </span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="关联工地：" :colon="false">
        <div class="site_class">
          <span v-for="item in site" :key="item.customer_id" class="site_item"
            >{{ item.name }}
          </span>
        </div>
      </a-form-model-item>
      <!-- <a-form-model-item label=" " :colon="false">
        <a-button>取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          确认保存
        </a-button>
      </a-form-model-item> -->
    </a-form-model>
    <audio src="../../assets/audio/baojingqi.mp3" id="baojing"></audio>
    <audio src="../../assets/audio/yanwu.mp3" id="yanwu"></audio>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from '@/utils/uploader'
import { toolbar, fontSize } from '@/utils/toolbar'

export default {
  data() {
    return {
      editor: null,
      editorData: '',
      form: {},
      site: [],
      secret: '',
      audio: [
        { name: '报警器报警', value: 'default' },
        { name: '烟雾报警器报警', value: 'yanwu' },
      ],
      activeAudio: 'default',
      rules: {
        img: [{ required: true, message: '请上传展示图' }],
      },
    }
  },
  watch: {
    editorData(val) {
      this.form.content = val
    },
  },
  created() {
    this.$post(domain + '/admin/v1/govService/index/getInfo').then((res) => {
      if (res.code === 0) {
        this.form = res.data
        this.secret = res.data.secret
        this.activeAudio = res.data.sound
      } else {
        this.$message.error(res.msg)
      }
    })

    this.$post(domain + '/admin/v1/govService/index/govCustomer').then(
      (res) => {
        if (res.code === 0) {
          this.site = res.data
          console.log(this.site)
        } else {
          this.$message.error(res.msg)
        }
      }
    )
  },
  mounted() {},
  methods: {
    getUpdateSecret() {
      this.$post(domain + '/admin/v1/govService/index/updateSecret').then(
        (res) => {
          if (res.code === 0) {
            this.form.secret = res.data
            this.secret = res.data
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    copySecret() {
      message.success('复制成功')
    },
    toIOC() {
      const a = document.createElement('a')
      a.href = 'http://ioc.jsspdzkj.cn/#/index?secret=' + this.secret
      a.target = 'NewWindow'
      a.click()
    },
    // 选择报警音效
    setAudio(value) {
      if (this.activeAudi == value) {
        return
      }
      this.activeAudio = value
      const audioDom = document.getElementById('baojing')
      const audioDom1 = document.getElementById('yanwu')
      if (value == 'default') {
        audioDom1.pause()
        audioDom.play()
      } else {
        audioDom.pause()
        audioDom1.play()
      }
      this.$post(domain + '/admin/v1/govService/index/configurationSound', {
        sound: value,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    width: 277px;
  }
  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}
.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-upload {
  width: 86px;
  height: 86px;
}
#editor {
  height: 560px;
  border: 1px solid #d9d9d9;
}
</style>
<style lang="less">
.form-edit {
  .upload-card {
    .ant-upload.ant-upload-select-picture-card {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
.secret_box {
  width: 378px;
  height: 32px;

  .secret {
    width: 136px;
    display: inline-block;
    background: #f0f2f5;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    box-sizing: content-box;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    padding-left: 14px;
    vertical-align: super;
  }
  .btn {
    width: 91px;
    height: 32px;
    background: #1677ff;
    border: 1px solid #1677ff;
    border-radius: 2px;
    float: right;
    box-sizing: content-box;
    cursor: pointer;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
}
.site_class {
  width: 820px;
  // height: 138px;
  // background: #f0f2f5;
  border-radius: 2px;
  // border: 1px solid #d9d9d9;
  // padding: 17px;
  .site_item {
    // height: 22px;
    font-size: 14px;
    display: inline-block;
    line-height: 22px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 3px 10px;
    margin-right: 24px;
    cursor: pointer;
    color: #060606;
  }
}
.audio_class {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #060606;
  cursor: pointer;
  .dot {
    font-size: 12px;
    margin: 0 8px;
    vertical-align: top;
  }
  .active_class {
    color: #1677ff;
  }
}
</style>
